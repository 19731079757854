.slick-list {
    height: 320px; /*360*/
    border-radius: 16px;
    background: #FFFFFF;
    /*border: 3px solid #667080;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.slick-slider {
    /*height: 432px;*/
}
.slick-arrow::before {
    display: none;
}
.slick-dots {
    display: flex;
    justify-content: center;
    gap: 32px;
    bottom: -90px; /*-60*/
}
.slick-dots > .slick-active > div {
    background: #07F285;
    opacity: 100%;
}
.slick-dots > li {
    margin: 0 16px;
}

.sliderCoin > .slick-slider > .slick-list {
    background: transparent;
    height: auto;
}

.sliderCoin .slick-track {
    display: flex;
    justify-content: center;
    gap: 50px;
}