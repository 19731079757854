.buttonBlock {
    width: 100%;
    background: #1C1C1C;
    border: 3px solid #667080;
    border-radius: 16px;
    padding: 40px 0;
}

.buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
}