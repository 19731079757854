.infoBox {
    width: 952px;
    background: #1C1C1C;
    border: 3px solid #667080;
    border-radius: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 32px 40px;
    margin-top: 64px;
}

.upperCase {
    text-transform: uppercase;
}

.priceCrypto {
    font-weight: 700;
}