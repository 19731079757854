.phoneInput {
    width: 100%;
    outline: none;
    border: none;
    font-size: 36px;
    background: transparent;
    color: #ffffff;
    font-weight: 700;
    margin-right: 40px;
    margin-left: 40px;
}

.phoneInput::placeholder {
    color: #FFFFFF;
}
